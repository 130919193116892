import React, { useState, useEffect } from 'react';
import { motion, useAnimation, Transition } from 'framer-motion';
import { useInView } from "react-intersection-observer";

// import { useAnimate, usePresence, AnimatePresence } from "framer-motion"

const ScrollAnimationComponent = (props: {
    children?: React.ReactNode;
    initial?: any;
    enter?: any;
    exit?: any;
    transition?: Transition;
    style?: any;
    threshold?: number;
}) => {
    const {
        children,
        initial,
        enter,
        exit,
        transition,
        style,
        threshold,
    } = props;

    const { ref, inView, entry } = useInView({
        /* Optional options */
        threshold: threshold ?? 0,
        // trackVisibility: true,
    });
    const [variantState, setVariantState] = useState('');

    const variants = {
        // init: initial,
        visible: enter,
        hidden: exit
    };

    useEffect(() => {
        if (inView) {
            setVariantState("visible");
        } else {
            setVariantState("hidden");
        }
    }, [inView]);

    return (
        <>
            <motion.div
                ref={ref}
                animate={variantState}
                initial={initial}
                variants={variants}
                transition={transition}
                style={style}
            >
                {children}
            </motion.div>
        </>
    );
};

export default ScrollAnimationComponent;