import React from 'react';
import ReactDOM from 'react-dom/client';
import { motion } from "framer-motion"
import './index.css';

// import App from './App';
import reportWebVitals from './reportWebVitals';

import LandingPage from './landing-page/LandingPage';

// TO INSPECT THE PAGE BEFORE 1ST RENDER
// debugger;

setTimeout(() => {
    const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement
    );

    root.render(
        <>
            <motion.div
                layout
                layoutScroll
                initial={{ opacity: 0.3, scale: 1.0, }}
                animate={{ opacity: 1, scale: 1.0, }}
                exit={{ opacity: 1, scale: 1.0, top: 0 }}
                transition={{
                    duration: 1.00,
                    ease: 'circInOut',
                }}
            >
                <LandingPage />
            </motion.div>
        </>
    );
}, 500);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
