import * as React from 'react';
import { alpha, Avatar, } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import ScrollAnimationComponent from '../components/ScrollAnimationComponent';

const items = [
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: 'NO INFLATION',
    description: 'Fair-launched game token',
    image: '/static/memetd/keypoints/Keypoint1.png',
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: 'GAME-FI EXPERIENCE',
    description: 'Obtain token from Playing',
    image: '/static/memetd/keypoints/Keypoint2.png',
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'MEME CULTURE',
    description: 'Your favorite meme is here to play.',
    image: '/static/memetd/keypoints/Keypoint3.png',
  }
];

export default function MemeTDKeyPoints() {
  return (
    <Box
      id="memetd_keyPoints"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        width: '100%',
        // backgroundColor: `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        // background: `url(static/bg/roadmap-bg.png) no-repeat center center`,
        // backgroundSize: 'cover',
        // color: 'white',
        // bgcolor: '#06090a',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <ScrollAnimationComponent
            initial={{ opacity: 0.0, scale: 1.0 }}
            enter={{ opacity: 1.0, scale: 1.0 }}
            exit={{ opacity: 0.0, scale: 1.0 }}
            transition={{
              delay: 0.2,
              duration: 0.7,
            }}
          >
            <Typography
              variant="h2"
              component="p"
              sx={{
                alignSelf: 'center',
                textAlign: 'center',
                fontSize: {
                  xs: '2.5rem',
                  sm: '3.0rem',
                  md: 'clamp(3.5rem, 10vw, 3.5rem)',
                },
                whiteSpace: 'no-wrap',
                pb: { xs: 6, },
              }}
            >
              Game Keypoints
            </Typography>
          </ScrollAnimationComponent>

          <ScrollAnimationComponent
            initial={{ opacity: 0.0, scale: 1.0 }}
            enter={{ opacity: 1.0, scale: 1.0 }}
            exit={{ opacity: 0.0, scale: 1.0 }}
            transition={{
              delay: 0.2,
              duration: 0.7,
            }}
          >
            <Typography variant="body1" sx={{ color: 'grey.400' }}>

            </Typography>

          </ScrollAnimationComponent>
        </Box>

        <Grid container spacing={1.5} sx={{
          pt: 5
        }}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={12} md={4} key={index} sx={{
              mt: {
                xs: 15,
                sm: 15,
                md: 0,
              }
            }}>
              <Box sx={{
                background: `#174a52 0 0 no-repeat padding-box`,
                border: `2px solid #FFF`,
                borderRadius: `24px`,
                boxShadow: `inset 4px 4px 0 #216772, 0 24px 0 #0003`,
              }}>
                <Stack
                  direction="column"
                  color="inherit"
                  component={Card}
                  spacing={1}
                  useFlexGap
                  sx={{
                    m: 3,
                    background: 'transparent',
                    border: 'none',
                    boxShadow: 'none',
                    height: 'auto',
                    overflow: 'visible',
                  }}
                >
                  <ScrollAnimationComponent
                    initial={{ opacity: 0.0, scale: 0.3 }}
                    enter={{ opacity: 1.0, scale: 1.0 }}
                    exit={{ opacity: 0.0, scale: 0.3 }}
                    transition={{
                      delay: 0.1 + (index * 0.1),
                      duration: 0.3,
                    }}
                  >
                    <Avatar variant="rounded"
                      src={item.image}
                      sx={{
                        border: `4px solid #fff`,
                        borderRadius: `24px`,
                        width: '100%',
                        height: 'auto',
                        transform: 'translateY(-100px)'
                      }} />
                    <Typography sx={{
                      textAlign: 'center',
                      fontWeight: '1000',
                      fontFamily: `"Pixelify Sans"`,
                      fontSize: '45px',
                      lineHeight: 1.0,
                      color: '#F4F07A',
                      WebkitTextStroke: '2px #000',
                      transform: 'translateY(-80px)',
                      height: '30px',
                      maxHeight: '30px',
                    }}>
                      {item.title}
                    </Typography>

                    <Typography sx={{
                      textAlign: 'center',
                      // left: '50%',
                      transform: 'translateY(-0px)',
                      width: '100%',
                      bottom: 0,
                      fontFamily: `"Pixelify Sans"`,
                    }}>
                      {item.description}
                    </Typography>

                  </ScrollAnimationComponent>
                </Stack>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box >
  );
}
