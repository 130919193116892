import * as React from 'react';
import { alpha, duration, styled, useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';

import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineDotProps,
  TimelineDotPropsColorOverrides,
  TimelineDotPropsVariantOverrides,
  TimelineOppositeContent,
} from '@mui/lab';
import {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';

import { OverridableStringUnion } from '@mui/types';

import {
  CardActionArea,
  Card,
  CardHeader,
  CardContent,
  CardMedia,
  CardActions,
} from '@mui/material';

import './MemeTDRoadmap.css';

import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import ScrollAnimationComponent from '../components/ScrollAnimationComponent';
import { delay } from 'framer-motion';


const DemoPaper = styled(Paper)(({ theme }) => ({
  // width: 120,
  // height: 120,
  padding: theme.spacing(2),
  ...theme.typography.body2,
  textAlign: 'center',
}));

function SVGShape() {
  return (<>
    <svg xmlns="http://www.w3.org/2000/svg" width="210px" height="10px">
      <path fillRule="evenodd" fillOpacity="0.102" d=" M5.000,-0.001 L30.000,-0.001 L25.000,9.999 L-0.000,9.999 L5.000,-0.001 Z"></path>
      <path fillRule="evenodd" fillOpacity="0.302" d=" M35.000,-0.001 L60.000,-0.001 L55.000,9.999 L30.000,9.999 L35.000,-0.001 Z"></path>
      <path fillRule="evenodd" fillOpacity="0.502" d=" M65.000,-0.001 L90.000,-0.001 L85.000,9.999 L60.000,9.999 L65.000,-0.001 Z"></path>
      <path fillRule="evenodd" fillOpacity="0.702" d=" M95.000,-0.001 L120.000,-0.001 L115.000,9.999 L90.000,9.999 L95.000,-0.001 Z"></path>
      <path fillRule="evenodd" fillOpacity="0.8" d=" M125.000,-0.001 L150.000,-0.001 L145.000,9.999 L120.000,9.999 L125.000,-0.001 Z"></path>
      <path fillRule="evenodd" fillOpacity="0.902" d=" M155.000,-0.001 L180.000,-0.001 L175.000,9.999 L150.000,9.999 L155.000,-0.001 Z"></path>
      <path fillRule="evenodd" d=" M185.000,-0.001 L210.000,-0.001 L210.000,9.999 L180.000,9.999 L185.000,-0.001 Z"></path>
    </svg>
  </>);
}

function TLItem(props: {
  children?: React.ReactNode,
  datetime?: React.ReactNode,
  label?: React.ReactNode,
  labelRight?: React.ReactNode,
  description?: React.ReactNode,

  icon?: React.ReactNode,
  iconColor?: OverridableStringUnion<'inherit' | 'grey' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning', TimelineDotPropsColorOverrides>,
  iconVariant?: OverridableStringUnion<'filled' | 'outlined', TimelineDotPropsVariantOverrides>,
  iconSX?: SxProps<Theme>,

  connector1SX?: SxProps<Theme>,
  connector2SX?: SxProps<Theme>,
}) {
  const {
    children,
    icon,
    iconColor,
    iconVariant,
    iconSX,

    connector1SX,
    connector2SX,

    datetime,
    label,
    labelRight,
    description,

  } = props;
  return (
    <>
      <TimelineItem className='roadmap-timeline-item'>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          align="right"
          variant="body2"
          color="text.secondary"
        >
          {datetime}
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector sx={connector1SX} />
          <TimelineDot color={iconColor} variant={iconVariant} sx={iconSX}>
            {icon}
          </TimelineDot>
          <TimelineConnector sx={connector2SX} />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          {/* <Typography variant="h6" component="span">
            {label}
          </Typography>
          <Typography>
            {description}
          </Typography> */}

          <ScrollAnimationComponent
            initial={{ opacity: 0.0, scale: 0.3 }}
            enter={{ opacity: 1.0, scale: 1.0 }}
            exit={{ opacity: 0.0, scale: 0.3 }}
            transition={{
              delay: 0.1,
              duration: 0.3,
            }}
          >
            <Card>
              <CardActionArea>
                <CardContent>
                  <Paper className='roadmap-timeline-item-header' component="div" sx={{ border: 'none', height: '5em', boxShadow: 'none', bgcolor: 'transparent', backgroundImage: 'none' }} >
                    <Typography
                      className='roadmap-timeline-item-header-text'
                      variant="h4"
                      component="span"
                      sx={{
                        position: 'absolute',
                        top: '1.1em',
                        left: '1.2em',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        fontFamily: `"Press Start 2P"`,
                        fontSize: {
                          xs: '16px',
                          sm: '16px',
                          md: '24px',
                          lg: '24px',
                        }
                      }}>
                      {label}
                    </Typography>
                    <Typography
                      className='roadmap-timeline-item-header-text'
                      variant="h4"
                      component="span"
                      sx={{
                        position: 'absolute',
                        top: '1.1em',
                        right: '1.0em',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        fontFamily: `"Press Start 2P"`,
                        fontSize: {
                          xs: '16px',
                          sm: '16px',
                          md: '24px',
                          lg: '24px',
                        }
                      }}>
                      {labelRight}
                    </Typography>
                    <Typography component="span" sx={{ position: 'absolute', top: '-10px', right: '0' }}>
                      <SVGShape />
                    </Typography>
                  </Paper>
                  <Typography
                    component='div'
                    className='roadmap-timeline-item-body-text'
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      ml: '1.2em',
                      fontFamily: `"Pixelify Sans"`,
                    }}>
                    {description}
                  </Typography>
                </CardContent>

                <CardActions sx={{ display: 'none' }}>
                  {/* <Button size="small">Share</Button>
                  <Button size="small">Learn More</Button> */}
                </CardActions>
              </CardActionArea>
            </Card>
          </ScrollAnimationComponent>
        </TimelineContent>
      </TimelineItem>
    </>
  );
};

export default function MemeTDRoadmap() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  // const mobile1 = useMediaQuery(theme.breakpoints.up("xs"));
  // console.log(`mobile`, mobile, mobile1);
  return (
    <>
      <Box
        id="memetd_roadmap"
        sx={(theme) => ({
          width: '100%',
          backgroundColor: `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
          background: `url(static/bg/roadmap-bg.png) no-repeat center center`,
          backgroundSize: 'cover',
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
        })}
      >
        <Container
          sx={{
            // display: 'flex',
            // flexDirection: 'column',
            alignItems: mobile ? 'right' : 'center',
            // pt: { xs: 14, sm: 20 },
            // pb: { xs: 8, sm: 12 },
          }}
        >
          <Box
            sx={{
              width: { sm: '100%', md: '100%' },
              textAlign: { sm: 'left', md: 'center' },
            }}
          >
            <Typography
              variant="h2"
              component="p"
              sx={{
                alignSelf: 'center',
                textAlign: 'center',
                fontSize: {
                  xs: '2.5rem',
                  sm: '3.0rem',
                  md: 'clamp(3.5rem, 10vw, 3.5rem)',
                },
                whiteSpace: 'no-wrap',
              }}
            >
              Roadmap
            </Typography>
          </Box>

          <Divider sx={{ width: '75%', margin: '0px auto !important', padding: '5px' }} />

          <Timeline
            position={mobile ? 'right' : 'alternate'}
            sx={{
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: mobile ? -0.0 : 1.0,
              },
            }}
          >
            <TLItem
              icon={<FastfoodIcon />}
              datetime={''}
              label={'PHASE 1'}
              labelRight={'25%'}
              description={<>
                <ul>
                  <li>Deploy website</li>
                  <li>Build social channel</li>
                  <li>Alpha launch on Telegram</li>
                  <li>Social Quest & Social Campaign</li>
                  <li>Token Airdrop</li>
                </ul>
              </>}

              connector1SX={{ display: 'none' }}
              connector2SX={{}}
            />

            <TLItem
              icon={<LaptopMacIcon />}
              iconColor={'primary'}
              datetime={''}
              label={'PHASE 2'}
              labelRight={'50%'}
              description={<>
                <ul>
                  <li>Beta Version</li>
                  <li>IDO & Exchange Listing</li>
                </ul>
              </>}

              connector1SX={{}}
              connector2SX={{}}
            />

            <TLItem
              icon={<HotelIcon />}
              iconColor={'primary'}
              iconVariant="outlined"
              datetime={''}
              label={'PHASE 3'}
              labelRight={'75%'}
              description={<>
                <ul>
                  <li>Official Launch with LiveOps</li>
                  <li>Social Marketing Push</li>
                </ul>
              </>}

              connector1SX={{}}
              connector2SX={{ bgcolor: 'secondary.main' }}
            />

            <TLItem
              icon={<RepeatIcon />}
              iconColor={'secondary'}
              iconVariant="filled"
              datetime={''}
              label={'PHASE 4'}
              labelRight={'100%'}
              description={<>
                <ul>
                  <li>Development of Meme Metaverse & Meme-based Game Center</li>
                  <li>Implementation of AR Technology</li>
                  <li>Location-based Card Collection</li>
                  <li>Transition to Fully On-chain Game</li>
                </ul>
              </>}

              connector1SX={{ bgcolor: 'secondary.main' }}
              connector2SX={{ display: 'none' }}
            />

          </Timeline>

        </Container>
      </Box>
    </>
  );
}
