import * as React from 'react';
import { PaletteMode } from '@mui/material';
import { styled } from '@mui/material'
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import ToggleColorMode from '../components/ToggleColorMode';

import './AppAppBar.css';

const logoStyle = {
  'width': '65px',
  'height': 'auto',
  'cursor': 'pointer',
  'border-radius': '9px',
  'border': `2px solid #fff`,
};

// // 👇 This component based on Toolbar
// const MyAppBar = styled(AppBar)(({ theme }) => ({
//   // paddingTop: '1.15rem',
//   backgroundColor: '#fff',
//   position: 'fixed',
//   width: '100vw !important',
//   maxWidth: '100vw !important',
//   paddingLeft: '0px',
//   paddingRight: '0px',
//   // color: 'rgba(0, 0, 0, 0.87)',

//   // 👇 Optional: consider to use theme.breakpoints for this
//   // ['@media (min-width: 600px)']: {
//   //   paddingLeft: '0px',
//   //   paddingRight: '0px',
//   // },
//   // ['@media (min-width: 1536px)']: {
//   //   paddingLeft: '0px',
//   //   paddingRight: '0px',
//   // },
// }));

interface AppAppBarProps {
  mode: PaletteMode;
  toggleColorMode: () => void;
}

function AppAppBar({ mode, toggleColorMode }: AppAppBarProps) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (sectionId: string) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: 'smooth' });
      window.scrollTo({
        top: targetScroll,
        behavior: 'smooth',
      });
      setOpen(false);
    }
  };

  return (
    <>
      <AppBar
        className='memetd-appbar'
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 0,
        }}
      >
        <Toolbar
          variant="regular"
          sx={(theme) => ({
            p: '2.5em 0em',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexShrink: 0,
            borderRadius: '0px',
            bgcolor:
              theme.palette.mode === 'light'
                ? 'rgba(255, 255, 255, 0.4)'
                : 'rgba(0, 0, 0, 0.4)',
            backdropFilter: 'blur(24px)',
            maxHeight: 40,
            border: '1px solid',
            borderColor: 'divider',
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
          })}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
              ml: '-18px',
              px: 0,
            }}
          >
            <img
              className='memetd-logo'
              src={
                'static/memetd/memetd-logo-350-v3.png'
              }
              style={logoStyle}
              alt="Logo"
            />
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>

            </Box>
          </Box>

          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: 0.5,
              alignItems: 'center',
            }}
          >
            <MenuItem
              onClick={() => scrollToSection('memetd_gameFeatures')}
              sx={{ py: '6px', px: '12px' }}
            >
              <Typography variant="body2" color="text.primary">
                Game Features
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => scrollToSection('memetd_keyPoints')}
              sx={{ py: '6px', px: '12px' }}
            >
              <Typography variant="body2" color="text.primary">
                Key Points
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => scrollToSection('memetd_tokenomics')}
              sx={{ py: '6px', px: '12px' }}
            >
              <Typography variant="body2" color="text.primary">
                Tokenomics
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => scrollToSection('memetd_roadmap')}
              sx={{ py: '6px', px: '12px' }}
            >
              <Typography variant="body2" color="text.primary">
                Roadmap
              </Typography>
            </MenuItem>

            <MenuItem
              href='https://news.memetd.game'
              target='_blank'
              component='a'
              sx={{ py: '6px', px: '12px' }}
            >
              <Typography variant="body2" color="text.primary">
                Docs
              </Typography>
            </MenuItem>

            {/* <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} /> */}

            {/*
              <Button
                color="primary"
                variant="text"
                size="small"
                component="a"
                href="/material-ui/getting-started/templates/sign-in/"
                target="_blank"
              >
                Sign in
              </Button>
              */}
            <Button
              color="primary"
              variant="contained"
              size="small"
              component="a"
              href='https://t.me/memetd_bot?start=r_883867370'
              target='_blank'
            >
              Play Now
            </Button>
          </Box>

          <Box sx={{ display: { sm: '', md: 'none' } }}>
            <Button
              variant="text"
              color="primary"
              aria-label="menu"
              onClick={toggleDrawer(true)}
              sx={{ minWidth: '30px', p: '4px' }}
            >
              <MenuIcon />
            </Button>
            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
              <Box
                sx={{
                  minWidth: '60dvw',
                  p: 2,
                  backgroundColor: 'background.paper',
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'end',
                    flexGrow: 1,
                  }}
                >
                  {/* <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} /> */}
                </Box>
                <MenuItem onClick={() => scrollToSection('memetd_gameFeatures')}>
                  Game Features
                </MenuItem>
                <MenuItem onClick={() => scrollToSection('memetd_keyPoints')}>
                  Key Points
                </MenuItem>
                <MenuItem onClick={() => scrollToSection('memetd_tokenomics')}>
                  Tokenomics
                </MenuItem>
                <MenuItem onClick={() => scrollToSection('memetd_roadmap')}>
                  Roadmap
                </MenuItem>
                <MenuItem href='https://news.memetd.game' target='_blank'>
                  Docs
                </MenuItem>
                {/* <MenuItem onClick={() => scrollToSection('faq')}>FAQ</MenuItem> */}
                <Divider />
                <MenuItem>
                  <Button
                    color="primary"
                    variant="contained"
                    component="a"
                    href="https://t.me/memetd_bot?start=r_883867370"
                    target="_blank"
                    sx={{ width: '100%' }}
                  >
                    PLAY NOW
                  </Button>
                </MenuItem>
                {/* <MenuItem>
                  <Button
                    color="primary"
                    variant="contained"
                    component="a"
                    href="/material-ui/getting-started/templates/sign-up/"
                    target="_blank"
                    sx={{ width: '100%' }}
                  >
                    Sign up
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button
                    color="primary"
                    variant="outlined"
                    component="a"
                    href="/material-ui/getting-started/templates/sign-in/"
                    target="_blank"
                    sx={{ width: '100%' }}
                  >
                    Sign in
                  </Button>
                </MenuItem> */}
              </Box>
            </Drawer>
          </Box>

        </Toolbar>
      </AppBar>
    </>
  );
}

export default AppAppBar;
