import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ScrollAnimationComponent from '../components/ScrollAnimationComponent';

export default function MemeTDHowToPlay() {
  return (
    <Box
      id="memetd_howtoplay"
      sx={(theme) => ({
        width: '100%',
        backgroundColor: `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        // background: `url(static/bg/banner-bg.png) no-repeat center center`,
        // backgroundSize: 'cover',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, },
        }}
      >
        <Box
          sx={{
            width: { sm: '80%', },
            textAlign: { sm: 'center', md: 'center' },
            alignSelf: 'center',
          }}
        >
          <ScrollAnimationComponent
            initial={{ opacity: 0.0, scale: 1.0 }}
            enter={{ opacity: 1.0, scale: 1.0 }}
            exit={{ opacity: 0.0, scale: 1.0 }}
            transition={{
              delay: 0.5,
              duration: 0.7,
            }}
          >
            <Typography
              variant="h2"
              component="p"
              sx={{
                alignSelf: 'center',
                textAlign: 'center',
                fontSize: 'clamp(2.5rem, 10vw, 3rem)',
                whiteSpace: 'no-wrap',
                pb: { xs: 6, },
              }}
            >
              The first Meme game on&nbsp;
              <Typography
                component="span"
                variant="h1"
                color='primary'
              >
                TON
              </Typography>
            </Typography>
          </ScrollAnimationComponent>

          <ScrollAnimationComponent
            initial={{ opacity: 0.0, scale: 1.0 }}
            enter={{ opacity: 1.0, scale: 1.0 }}
            exit={{ opacity: 0.0, scale: 1.0 }}
            transition={{
              delay: 0.5,
              duration: 0.7,
            }}
          >
            <Typography
              textAlign="center"
              color="text.secondary"
            >
              "Meme Tower Defense" is an idle tower defense game where players protect their precious Meme coins from the evil forces in the crypto world.
            </Typography>
          </ScrollAnimationComponent>
        </Box>
      </Container>
    </Box>
  );
}
