import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

export default function MemeTDWhoWeAre() {
  return (
    <Box
      id="memetd_whoweare"
      sx={(theme) => ({
        width: '100%',
        backgroundColor: `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        background: `url(static/bg/roadmap-bg.png) no-repeat center center`,
        backgroundSize: 'cover',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >

        <Typography
          variant="h2"
          component="p"
          sx={{
            alignSelf: 'center',
            textAlign: 'center',
            fontSize: {
              xs: '2.5rem',
              sm: '3.0rem',
              md: 'clamp(3.5rem, 10vw, 3.5rem)',
            },
            whiteSpace: 'no-wrap',
            pb: { xs: 6, },
          }}
        >
          WHO WE ARE
        </Typography>
        <Typography variant="h5" sx={{ color: 'grey.400', textAlign: 'center' }}>
          🔥Get ready to be amazed.<br />
          We’re gamers and meme enthusiasts who will bring the best of both worlds into reality.
        </Typography>

      </Container>
    </Box>
  );
}
