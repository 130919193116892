import * as React from 'react';
// import { PaletteMode } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import AppAppBar from './sections/AppAppBar';
import Highlights from './sections/Highlights';
import Testimonials from './components/Testimonials';
import FAQ from './sections/FAQ';
import Footer from './sections/Footer';
import ScrollAnimationComponent from './components/ScrollAnimationComponent';
// import Hero from './sections/Hero';
// import LogoCollection from './sections/LogoCollection';
// import Pricing from './sections/Pricing';
// import Features from './sections/Features';
// import ToggleCustomTheme from './components/ToggleCustomTheme';
// import getLPTheme from './getLPTheme';
import getMemeTheme from './getMemeTheme';
import MemeTD from './sections/MemeTD';
import MemeTDHowToPlay from './sections/MemeTDHowToPlay';
import MemeTDRoadmap from './sections/MemeTDRoadmap';
import MemeTDComunity from './sections/MemeTDComunity';
import MemeTDWhoWeAre from './sections/MemeTDWhoWeAre';
import MemeTDTokenomic from './sections/MemeTDTokenomic';
import MemeTDFeatures from './sections/MemeTDFeatures';
import MemeTDKeyPoints from './sections/MemeTDKeyPoints';
import MemeTDLogoCollection from './sections/MemeTDLogoCollection';

export default function LandingPage() {
  const mode = 'dark';
  // const [mode, setMode] = React.useState<PaletteMode>('light');
  // const [showCustomTheme, setShowCustomTheme] = React.useState(false);
  const customTheme = createTheme(getMemeTheme(mode));
  // const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    // setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  // const toggleCustomTheme = () => {
  //   setShowCustomTheme((prev) => !prev);
  // };

  return (
    <ThemeProvider
      theme={customTheme}
    // theme={showCustomTheme ? customTheme : defaultTheme}
    >
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <MemeTD />
      {/* <Hero /> */}
      <Box sx={{ bgcolor: 'background.default' }}>
        <MemeTDHowToPlay />
        <MemeTDFeatures />
        <MemeTDKeyPoints />
        <MemeTDTokenomic />
        <MemeTDRoadmap />
        {/* <MemeTDComunity /> */}
        {/* <Testimonials /> */}
        {/* <Divider /> */}
        {/* <Highlights /> */}
        {/* <Divider /> */}
        {/* <Pricing /> */}
        {/* <LogoCollection /> */}
        {/* <Divider /> */}
        {/* <Divider /> */}
        {/* <FAQ /> */}
        <MemeTDLogoCollection />
        <MemeTDWhoWeAre />
        <Divider />
        <Footer />
      </Box>

      {/*
      <ToggleCustomTheme
        showCustomTheme={showCustomTheme}
        toggleCustomTheme={toggleCustomTheme}
      />
      */}
    </ThemeProvider>
  );
}
