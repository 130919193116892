import * as React from 'react';
import { alpha, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Grid from '@mui/material/Grid';
import TweenCounterComponent from '../components/TweenCounterComponent';
import { text } from 'stream/consumers';
import { Discord as DiscordIcon, Twitter as TwitterIcon, Telegram as TelegramIcon, Controller as ControllerIcon } from 'react-bootstrap-icons';

import './MemeTD.css';

const logoStyle = {
  'border-radius': '20px',
  'border': `2px solid #fff`,
  'animation': `rebounds_animation 2s ease-in-out infinite`,
};

const CardItem = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#000' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const CardTextStyles = {
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  // fontSize: 'calc(0.5em + 1vw)',
};

function GridCardItemStats(props: { count?: number, label?: any, children?: React.ReactNode }) {
  const { children, count, label } = props;
  return (<>
    <Grid item lg={3} md={6} sm={6} xs={12}>
      <CardItem>
        <CardContent>
          <Typography variant="h2" color="primary.main" component="div">
            <TweenCounterComponent from={0} to={count} duration={1.5} />
          </Typography>
          <Typography
            color="text.secondary"
            sx={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              // fontSize: 'calc(0.5em + 1vw)',
            }}>
            {label}
          </Typography>
          {children}
        </CardContent>
      </CardItem>
    </Grid>
  </>);
}

function GridCardItem(props: { text?: any, label?: any }) {
  const { text, label } = props;
  return (<>
    <Grid item lg={4} md={4} sm={12} xs={12}>
      <CardItem>
        <CardContent>
          <Typography
            variant="h4"
            color="secondary.main"
            sx={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              fontFamily: `"Pixelify Sans"`,
              // fontSize: 'calc(0.5em + 1vw)',
            }}
          >
            {label}
          </Typography>
          <Typography variant="h5" color="primary.main" component="div"
            sx={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              fontFamily: `"Pixelify Sans"`,
              // fontSize: 'calc(0.5em + 1vw)',
            }}
          >
            {text}
          </Typography>
        </CardContent>
      </CardItem>
    </Grid>
  </>);
}

export default function MemeTD() {
  return (
    <>
      <Box
        id="hero"
        sx={(theme) => ({
          width: '100%',
          // backgroundColor: `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
          // backgroundColor: `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
          // background: `url(static/bg/banner-bg.png) no-repeat center center`,
          // background: `url(static/memetd/features/Feature${(Math.floor(new Date().getTime() % 6) + 1).toFixed(0)}.png) no-repeat center center`,
          background: `url(static/memetd/features/Feature2.png) no-repeat center center`,
          backgroundSize: 'cover',
        })}
      >
        <Box
          sx={(theme) => ({
            m: '0',
            p: '0',
            width: '100%',
            height: '100%',
            bgcolor:
              theme.palette.mode === 'light' ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.4)',
            backdropFilter: 'blur(3px)',
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
          })}
        >
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              pt: { xs: 14, sm: 20 },
              pb: { xs: 8, sm: 12 },
            }}
          >
            <Stack
              direction={{ xs: 'column', sm: 'column', md: 'column', lg: 'column' }}
              alignSelf="center"
              spacing={1}
              useFlexGap
              sx={{
                pt: 2,
                pb: 2,
                width: { xs: '100%', sm: 'auto' },
              }}
            >
              <Box
                id="image"
                sx={(theme) => ({
                  alignSelf: 'center',
                  borderRadius: '20px',
                  outline: 'none',
                  width: { xs: '300px' }
                  // outline: '1px solid',
                  // outlineColor:
                  //   theme.palette.mode === 'light'
                  //     ? alpha('#BFCCD9', 0.5)
                  //     : alpha('#9CCCFC', 0.1),
                  // boxShadow:
                  //   theme.palette.mode === 'light'
                  //     ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                  //     : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                })}
              >
                <img src="/static/memetd/memetd-logo-350-v3.png" width='100%' style={logoStyle} />
              </Box>

              {/*
            <Typography
              variant="h1"
              component="span"
              sx={{
                flexDirection: { xs: 'column', md: 'row' },
                alignSelf: 'center',
                textAlign: 'left',
                fontSize: 'clamp(3.5rem, 10vw, 4rem)',
                whiteSpace: 'no-wrap',
              }}
            >
              The first Meme game on&nbsp;
              <Typography
                component="span"
                variant="h1"
                sx={{
                  color: (theme) =>
                    theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
                }}
              >
                TON
              </Typography>
            </Typography>

            <Typography
              textAlign="left"
              color="text.secondary"
              sx={{ width: { sm: '100%', md: '80%' } }}
            >
              "Meme Tower Defense" is a crypto-based tower defense game where players defend against waves of memes using towers.&nbsp;
              Earn crypto rewards by strategically placing meme-themed towers to fend off increasingly challenging waves.&nbsp;
              Collaborate with friends, trade assets, and compete for leaderboard supremacy in this innovative fusion of crypto and gaming.
            </Typography>
            */}

              <Button
                className="button-play"
                variant="contained"
                color="secondary"
                href='https://t.me/memetd_bot?start=r_883867370'
                target='_blank'
                component="a"
                startIcon={<ControllerIcon />}
                sx={{
                  backgroundColor: 'rgba(0, 0, 0, 0.63)',
                  border: '1px solid rgba(0, 255, 163, 0.5)',
                  alignSelf: 'center',
                  width: { xs: '100%', sm: '300px' },
                  height: { xs: '100%', sm: '3em' },
                  minWidth: { xs: '220px' },
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  fontSize: 'max(calc(0.6em + 1vw), 1em)',
                  fontFamily: `"Pixelify Sans"`,
                  borderRadius: '10px',
                  ":hover": {
                    border: '1px solid #fee600',
                    backgroundColor: '#000',
                  }
                }}
              >
                <span className='rainbow_text_animated'>PLAY NOW</span>
              </Button>
              <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8, fontFamily: `"Pixelify Sans"` }}>
                By clicking &quot;Play now&quot; you agree to our&nbsp;
                <Link href="#" color="primary">
                  Terms & Conditions
                </Link>
                .
              </Typography>

              <Divider sx={{ width: '75%', margin: '0px auto !important', padding: '5px' }} />

              <Typography variant="subtitle2" textAlign="center" sx={{ opacity: 0.8, p: 0, fontFamily: `"Pixelify Sans"` }}>
                Hunt for a referral code to get started
              </Typography>

              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                alignSelf="center"
                spacing={1}
                useFlexGap
                sx={{ width: { xs: '100%', sm: 'auto' } }}
              >

                <Button
                  variant="outlined"
                  color="primary"
                  href='https://twitter.com/MemeTD_official'
                  target='_blank'
                  component="a"
                  startIcon={<TwitterIcon />}
                  sx={{
                    width: { xs: '100%', sm: 'auto' },
                    minWidth: { xs: '220px' },
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  @MemeTD_Official
                </Button>

                <Button
                  variant="outlined"
                  color="primary"
                  href='https://t.me/MemeTDgame'
                  target='_blank'
                  component="a"
                  startIcon={<TelegramIcon />}
                  sx={{
                    width: { xs: '100%', sm: 'auto' },
                    minWidth: { xs: '220px' },
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  @MemeTD_Official
                </Button>

              </Stack>

            </Stack>

            <Grid
              container
              spacing={1}
              sx={{
                mt: '3em'
              }}
            >
              <GridCardItem label={'Instant'} text={'Play on Telegram'} />
              <GridCardItem label={'Social'} text={'Join Clans, make Friends'} />
              <GridCardItem label={'Depth'} text={'Master the Strategy'} />
            </Grid>
          </Container>
        </Box>
      </Box>
    </>
  );
}
