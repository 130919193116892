import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, makeStyles } from '@mui/material';
import ScrollAnimationComponent from '../components/ScrollAnimationComponent';
export default function MemeTDTokenomic() {
  const tableData = [
    { name: 'Name', value: 'MemeTD', color: '#3399ff' },
    { name: 'Ticker', value: 'METD', color: '#ff66cc' },
    { name: 'Chain', value: 'TON', color: '#cc99ff' },
    { name: 'Total Supply', value: '10,000,000,000', color: '#ffcc00' },
    { name: 'Max Supply', value: '10,000,000,000', color: '#66ff66' },
    { name: 'Token Type', value: 'Governance/Utility', color: '#ff9999' },
    { name: 'Use Case', value: 'Stacking/In-app Purchase/Rewards', color: '#5fe6c8' },
    // { name: 'Marketing & Partnerships', value: '150,000', color: '#3399ff' },
    // { name: 'Community Incentives', value: '100,000', color: '#ff66cc' },
    // { name: 'Ecosystem Growth', value: '50,000', color: '#cc99ff' },
  ];

  return (
    <Box
      id="memetd_tokenomics"
      sx={(theme) => ({
        width: '100%',
        backgroundColor: `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        // background: `url(static/bg/banner-bg.png) no-repeat center center`,
        // backgroundSize: 'cover',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 8, },
          pb: { xs: 8, },
        }}
      >
        <Box
          sx={{
            width: { sm: '80%', },
            textAlign: { sm: 'center', md: 'center' },
            alignSelf: 'center',
          }}
        >
          <ScrollAnimationComponent
            initial={{ opacity: 0.0, scale: 1.0 }}
            enter={{ opacity: 1.0, scale: 1.0 }}
            exit={{ opacity: 0.0, scale: 1.0 }}
            transition={{
              delay: 0.5,
              duration: 0.7,
            }}
          >
            <Typography
              variant="h2"
              component="p"
              sx={{
                alignSelf: 'center',
                textAlign: 'center',
                fontSize: {
                  xs: '2.5rem',
                  sm: '3.0rem',
                  md: 'clamp(3.5rem, 10vw, 3.5rem)',
                },
                whiteSpace: 'no-wrap',
                pb: { xs: 6, },
              }}
            >
              Tokenomics
            </Typography>
          </ScrollAnimationComponent>

          <Divider sx={{ width: '75%', margin: '0px auto !important', padding: '5px' }} />

          <ScrollAnimationComponent
            initial={{ opacity: 0.0, scale: 1.0 }}
            enter={{ opacity: 1.0, scale: 1.0 }}
            exit={{ opacity: 0.0, scale: 1.0 }}
            transition={{
              delay: 0.5,
              duration: 0.7,
            }}
          >
            <TableContainer
              component={Paper}
              sx={{
                // alignSelf: 'center',
                margin: 'auto',
                maxWidth: { xs: '100%', sm: '100%', md: '75%' },
              }}>
              <Table sx={{
                // alignSelf: 'center',
              }}>
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#00ffa3' }}>
                    <TableCell align="center" width='35%'
                      sx={{
                        width: { xs: '50%', sm: '35%', md: '35%' }
                      }}>
                      <Typography variant='h5' color='#000'
                        sx={{
                          fontSize: { xs: '12px', sm: '14px', md: '20px' },
                        }}>
                        Stats
                      </Typography>
                    </TableCell >
                    <TableCell align="center"
                      sx={{
                        width: { xs: '50%', sm: '65%', md: '65%' }
                      }}>
                      <Typography variant='h5' color='#000'
                        sx={{
                          fontSize: { xs: '12px', sm: '14px', md: '20px' },
                        }}>
                        Details
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row" style={{ color: row.color }} align="center"
                        sx={{
                          fontSize: { xs: '12px', sm: '14px', md: '20px' },
                        }}>
                        {row.name}
                      </TableCell>
                      <TableCell align="center" style={{ color: row.color }}
                        sx={{
                          fontSize: { xs: '12px', sm: '14px', md: '20px' },
                        }}>
                        {row.value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </ScrollAnimationComponent>
        </Box>
      </Container >
    </Box >
  );
}
